import {useUser} from "@/api/user/useUser";
import {FaChevronDown, FaCog} from "react-icons/fa";
import {Avatar, Menu} from "@ark-ui/react";
import {BiCog, BiLogOut, BiSupport} from "react-icons/bi";
import {IoSparklesSharp} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {useLogout} from "@/api/auth/logout/useLogout";
import { calculatePages } from '@/utils/tokens';

const options  = [
    {
        icon : <BiCog></BiCog>,
        text : "Ajustes",
        url  : "/settings"
    },
    {
        icon : <BiSupport></BiSupport>,
        text : "Soporte",
        url  : "/soporte"
    },
]

function UserMenuButton() {
    const {data, status} = useUser()
    const {mutate} = useLogout({
        onSuccess: () => navigate("/login")
    })
    const navigate = useNavigate()

    if(status === "pending") return "pending"
    if(status === "error") return "error"

    return (
        <Menu.Root>
            <Menu.Trigger className="bg-brand_primary-500 outline-0 border-0 flex gap-4 items-center cursor-pointer hover:bg-brand_primary-700 p-1 rounded-full">
                    <div className="w-[35px] h-[35px] rounded-full bg-white overflow-hidden">
                        <Avatar.Root className="w-[35px] h-[35px] bg-gray-100 flex items-center justify-center rounded-full overflow-hidden">
                            <Avatar.Fallback className="bg-gray-100 text-brand_primary-800  font-semibold">{data.name.slice(0, 1).toUpperCase() + data.surname.slice(0, 1).toUpperCase()}</Avatar.Fallback>
                        </Avatar.Root>
                    </div>
                    <div className="gap-2 items-center hidden md:flex">
                        <div className="font-semibold text-white text-base">{data?.name} {data?.surname}</div>
                        <div className="text-white text-xs pr-3">
                            <FaChevronDown className=" pt-1 "></FaChevronDown>
                        </div>
                    </div>
            </Menu.Trigger>
            <Menu.Positioner>
                <Menu.Content className="outline-0 bg-brand_primary-500 p-3 w-[200px] mt-3 rounded-xl object-fill border-solid border-1 border-brand_primary-700">
                    <div className="w-full flex gap-3 items-center py-2">
                        <Avatar.Root className="min-w-[35px] min-h-[35px] bg-gray-100 flex items-center justify-center rounded-full overflow-hidden">
                            <Avatar.Fallback className="bg-gray-100  font-semibold text-brand_primary-800">{data.name.slice(0, 1).toUpperCase() + data.surname.slice(0, 1).toUpperCase()}</Avatar.Fallback>
                        </Avatar.Root>
                        <div className="flex flex-col gap-0">
                            <div className="font-semibold text-white text-sm text-left">{data?.name} {data?.surname}</div>
                            <div className="text-opacity-60 text-white text-xs text-left">{data?.username}</div>
                        </div>
                    </div>
                    <div className="mt-2 mb-4 ml-2 flex text-sm items-center gap-2">
                        <IoSparklesSharp size={20} className="text-white" />
                        <p className="text-white text-opacity-60">
                            { data ? <>{
                                data.tokens == 0 ? '0' :
                                data.tokens < 800 ? '1' :
                                '+'+calculatePages(data.tokens)
                            }</>
                            : '...' }
                            { data && data.tokens > 0 && data.tokens < 800 ? 
                                ' página IA' :
                                ' páginas IA'
                            }   
                        </p>
                    </div>
                    <Menu.Separator className="bg-opacity-10 bg-white h-[1px] outline-0 border-0"></Menu.Separator>
                    {
                        options.map((x, i) => (
                            <Menu.Item
                                onClick={() => navigate(x.url ?? "")}
                                key={i} value="hola" className="
                    cursor-pointer flex items-center text-white gap-2 w-full p-2 box-border
                    hover:bg-white
                    hover:bg-opacity-10
                    rounded-xl
                    font-semibold
                    text-xl
                    ">
                                {
                                    x.icon
                                }
                                <p className=" text-sm">{x.text}</p>
                            </Menu.Item>
                        ))
                    }
                    <Menu.Separator className="bg-opacity-10 bg-white h-[1px] outline-0 border-0"></Menu.Separator>
                    <Menu.Item onClick={() => mutate()} value="hola" className="
                    cursor-pointer flex items-center  gap-2 w-full p-2 box-border
                    hover:bg-white
                    hover:bg-opacity-10
                    rounded-xl
                    font-semibold
                    text-xl
                    text-white
                    ">
                        <BiLogOut></BiLogOut>
                        <p className=" text-sm">Cerrar sesión</p>
                    </Menu.Item>
                </Menu.Content>
            </Menu.Positioner>
        </Menu.Root>
    );
}

export default UserMenuButton;