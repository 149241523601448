import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

export interface UpdateUserRequestData {
    name: string;
    surname: string;
    email: string;
    username: string;
    birthdate: Date;
    location: string;
    education: string;
    institution: string;
    qualification: string;
    goal: string;
    restDays: boolean[];
}

interface UpdateUserResponseData {
    message: string;
}

export const updateUserRequest = async (updateUserData: UpdateUserRequestData) => {
    try {
        const response = await apiClient.post<UpdateUserResponseData>(
            "/user/update",
            updateUserData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};

export const useUpdateUser = (
    options: UseMutationOptions<UpdateUserResponseData, AxiosError, UpdateUserRequestData, null>
) => {
    return useMutation<UpdateUserResponseData, AxiosError, UpdateUserRequestData, null>({
        mutationFn: updateUserRequest,
        ...options
    });
};
