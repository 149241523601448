import {Link} from 'react-router-dom'
import {useFormikContext} from 'formik'
import {MdMail} from "react-icons/md";
import Button from "@/components/ui/Button";

export default function RegisterSuccess() {
    const {values} = useFormikContext<{email : string}>();

	return (
        <div className='h-full flex flex-col items-center justify-center gap-20'>
            <div className='flex flex-col items-center justify-center '>
                <div className="w-[150px] h-[150px] bg-brand_secondary-500 flex justify-center items-center text-white rounded-full">
                    <MdMail size={75} />
                </div>
                <h2 className='text-secondary-color font-bold m-0 mb-4 text-4xl mt-12'>
                    Revisa tu correo
                </h2>
                <h3 className='text-xl text-center text-gray-500'>
                    Te hemos enviado un correo de confirmación a <b>{values['email']}</b>
                </h3>
            </div>
            <Link to='/login'>
                <Button className='w-32'>Listo</Button>
            </Link>
        </div>
    )
}
