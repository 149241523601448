import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface UserResponseData {
    name: string;
    surname: string;
    email: string;
    username: string;
    birthdate: Date;
    location: string;
    education: string;
    institution: string;
    qualification: string;
    goal: string;
    restDays: boolean[];
    tokens: number;
}

export const getUserData = async (): Promise<UserResponseData> => {
    const response = await apiClient.get<UserResponseData>('/user/data');
    return response.data;
};

export const useUser = (options?: UseQueryOptions<UserResponseData, AxiosError>) => {
    return useQuery<UserResponseData, AxiosError>({
        queryKey: ['user'],
        queryFn: getUserData,
        ...options,
    });
};
