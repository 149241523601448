import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import { queryClient } from '@/api/client'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'
import {CardType} from '@/types/db/card'

interface ImportWithAIRequestData {
    deckId: string,
    content: string, 
    cardType: CardType,
    userPrompt: string,
    extraContext: string
}

interface ImportWithAIResponseData {
    message : string
}

interface ImportWithAIErrorData{
    error: string
}

export const importWithAIRequest = async (importData: ImportWithAIRequestData) => {
    try{
        const maxLength = 40000; // Division de texto para evitar payload to large
        let contentParts = [];
        for (let i = 0; i < importData.content.length; i += maxLength) {
            contentParts.push(importData.content.substring(i, i + maxLength));
        }
        const extraContextTrim = importData.extraContext.substring(0, 10000);
        let response;
        for (let i = 0; i < contentParts.length; i++) {
            const partData = { ...importData, content: contentParts[i], extraContext: extraContextTrim };
            response = await apiClient.post<ImportWithAIRequestData, AxiosResponse<ImportWithAIResponseData, ImportWithAIErrorData>>(
                `/card/create_with_ai?final=${i == contentParts.length - 1}`, partData
            )
        }
        return response!.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useImportWithAI = (options: UseMutationOptions<ImportWithAIResponseData, ImportWithAIErrorData, ImportWithAIRequestData, null>) =>{
    return useMutation<ImportWithAIResponseData, ImportWithAIErrorData, ImportWithAIRequestData, null>({
        mutationFn: importWithAIRequest,
        ...options
    })
}
