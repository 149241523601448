import Form from "@/components/forms/core/Form";
import landingImage from '@/assets/landingImage.svg'
import '@/styles/ContactForm.css'
import { useCreateFeedback } from '@/api/feedback/useCreateFeedback'
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import FormTextarea from '@/components/forms/core/FormTextarea';
import {useUser} from '@/api/user/useUser'
import { contactSchema } from './schema';
import { useToast } from "@/components/ui/ToastContext";

export default function Support () {

    const {createToast} = useToast();
    const {mutateAsync} = useCreateFeedback({
        onSuccess:  async () => {
            createToast({
                title: 'Feedback recibido', 
                description: 'Gracias por tu mensaje', 
                type: 'success'
            })
        }
    })
    const {data: userData} = useUser();

  return (
    <div className={`h-full w-full mx-auto max-w-[1000px] grid grid-cols-1 lg:grid-cols-2`}>
        <div className={`flex flex-col items-start justify-center w-full`}>
            <h2 className='text-[var(--color-primary)]'>Contáctanos</h2>
            <h3>Problemas, sugerencias, feedback...</h3>
            <h3>¡Estamos aquí para ayudarte!</h3>
            <Form className='w-full'
                validationSchema={contactSchema}
                initialValues={{message: ""}}
                onSubmit={async (values, { resetForm }) => {
                    await mutateAsync({
                        ...values,
                        nombre: userData?.name || '',
                        apellidos: userData?.surname || '',
                        email: userData?.email || ''
                    });
                    resetForm()
                }} 
            >
                <div className="mt-8">
                    <FormTextarea className='mb-2 !h-40' name="message" placeholder="Deja tu mensaje aquí..." label={""}/>
                </div>
                <div className="flex w-full items-center justify-center gap-3 mt-6">
                    <FormSubmitButton className="px-7 w-full bg-brand_secondary-500 py-2 text-white font-semibold rounded-full">
                        Enviar
                    </FormSubmitButton>
                </div>
            </Form>
        </div>
        <div className='
            hidden lg:block w-full p-5 flex items-center justify-center m-5'>
            <img className='w-full' src={landingImage} />
        </div>
    </div>
  )
}
