import React from 'react';
import {useField} from "formik";
import Textarea from '@/components/ui/Textarea';

interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string
}

export default function FormTextarea({ label, ...props} : TextareaProps) {
    const [field, meta] = useField(props.name ?? "");

    return (
        <div className="w-full h-auto flex flex-col gap-1">
            {label && <label className="font-semibold text-gray-600 !text-sm">
                {label}
            </label>}
            <Textarea {...field} {...props}/>
            {meta.touched && meta.error ? (
                <p className="!text-xs text-red-500">{meta.error}</p>
            ) : null}
        </div>
    );
}
