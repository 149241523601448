import {useToast} from "@/components/ui/ToastContext";
import {useUser} from "@/api/user/useUser";
import {UpdateUserRequestData, useUpdateUser} from "@/api/user/useUpdateUser";
import {queryClient} from "@/api/client";
import {Formik} from "formik";
import FormInput from "@/components/forms/core/FormInput";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {BlockLayout} from "@/pages/App/pages/Settings/components/BlockLayout";
import FetchLayout from "@/components/layout/FetchLayout";

function ModifyUserForm() {
    const {createToast} = useToast();
    const {status, data} = useUser()
    const { mutateAsync } = useUpdateUser({
        onSuccess: async () => {
            createToast({
                type: "success",
                title: "Usuario modificado con éxito",
            })
            await queryClient.invalidateQueries({
                queryKey: ["user"]
            })
        }
    })

    return (
            <div className="bg-gray-100 rounded-xl">
                <FetchLayout status={status}>
                    <Formik initialValues={{...data}} onSubmit={async (values) => {
                        await mutateAsync(values as UpdateUserRequestData)
                    }}>
                        <>
                            <BlockLayout title="Perfil" className="grid md:grid-cols-1 gap-3">
                                <div className="grid md:grid-cols-2 gap-3">
                                    <FormInput label="Nombre" name="nombre" size="md" variant="secondary" defaultValue={data?.name} />
                                    <FormInput label="Apellidos" name="apellidos" size="md" variant="secondary" defaultValue={data?.surname} />
                                </div>
                                <FormInput label="Nombre de usuario" name="usuario" size="md" variant="secondary" defaultValue={data?.username} />
                            </BlockLayout>
                            <BlockLayout title="Datos académicos" className="grid md:grid-cols-1 gap-3">
                                <div className="grid md:grid-cols-2 gap-3">
                                    <FormInput label="¿De dónde eres?" name="ubicacion" size="md" variant="secondary" defaultValue={data?.location} />
                                    <FormInput label="¿Qué estudias?" name="tipo_estudios" size="md" variant="secondary" defaultValue={data?.education} />
                                </div>
                                <FormInput label="¿Qué grado?" name="titulacion" size="md" variant="secondary" defaultValue={data?.qualification} />
                                <FormInput label="¿En que universidad?" name="centro_estudios" size="md" variant="secondary" defaultValue={data?.institution} />
                                <FormSubmitButton className="w-fit">Guardar cambios</FormSubmitButton>
                            </BlockLayout>
                        </>
                    </Formik>
                </FetchLayout>
            </div>
    );
}

export default ModifyUserForm;
