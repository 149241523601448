import {FormFileField} from "@/components/forms/core/FormFileField";
import {LuFileBadge} from "react-icons/lu";
import FormInput from "@/components/forms/core/FormInput";
import FormSelect from "@/components/forms/core/FormSelect";
import {FilePreviewCard} from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/fields/FilePreviewCard";

function ExtraContextForm() {
    return (
        <div className="py-10">
            <div className="w-full justify-center flex">
                <h1 className="text-3xl text-gray-800">Personaliza tu respuesta</h1>
            </div>
            <div className="flex flex-row mb-5 gap-5">
                    <FormInput
                        className="h-12" 
                        name="extraContext"
                        label="¿Sobre que quieres que te preguntemos?"
                        placeholder="Fechas, Nombres Propios, Genes, Leyes, Fármacos..." 
                    />
                {/*<div className="w-2/3">
                    <FormInput
                        className="h-12" 
                        name="extraContext"
                        label="¿Sobre que quieres que te preguntemos?"
                        placeholder="Fechas, Nombres Propios, Genes, Leyes, Fármacos..." 
                    />
                </div>
                <div className="w-1/3">
                    <FormSelect
                        className="h-12 w-12!" 
                        name="cardsAmount"
                        label="¿Cuantas cartas quieres?"
                        options={[
                            {value: '5', label: '<10'},
                            {value: '10', label: '≈10'},
                            {value: '20', label: '≈20'},
                            {value: '25', label: '>20'},
                        ]}
                    />
                </div>*/}
            </div>
            <div className="mb-5">
                <h3 className="text-sm font-semibold text-gray-600 pb-3">¿Te gustaria que basaramos las preguntas en algun examen?</h3>
                <FormFileField 
                    name="exams"
                    label="Añade examenes con preguntas similares para mejorar la calidad del resultado"
                    icon={<LuFileBadge size={50} />}
                    maxFiles={5}
                    previewCard={
                        (file) => <FilePreviewCard key={file.webkitRelativePath} file={file}/>
                    }
                />
            </div>
        </div>
    );
}

export default ExtraContextForm;