import {FormFileField} from "@/components/forms/core/FormFileField";
import {FilePreviewCard} from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/fields/FilePreviewCard";
import {LuFileText} from "react-icons/lu";

function AddDocumentsForm() {
    return (
        <div className="py-10">
            <div className="w-full justify-center flex">
                <h1 className="text-3xl text-gray-800">Sube tus apuntes en PDF</h1>
            </div>
            <FormFileField
                name="files"
                label="Arrastra tus archivos aquí o haz click para seleccionarlos"
                icon={<LuFileText size={50} />}
                maxFiles={5}
                previewCard={
                    (file) => <FilePreviewCard key={file.webkitRelativePath} file={file}/>
                }
            />
        </div>
    );
}

export default AddDocumentsForm;